<script setup lang="ts">
defineProps<{
  href: string;
  src: string;
  alt?: string;
  width: number;
  height: number;
  title: string;
}>();

const ariaLabel = 'Go to Unitb Home';
</script>

<template>
  <a class="u-logo" :href="href" :aria-label="ariaLabel">
    <img v-bind="{ src, alt, title }" width="91" height="28" />
  </a>
</template>

<style scoped lang="scss">
@layer components {
  .u-logo {
    :deep(img) {
      width: 4.07256rem;
      height: 1.25rem;

      @include mq-above($screen-medium) {
        width: 4.88713rem;
        height: 1.5rem;
      }

      @include mq-above($screen-large) {
        width: 5.6875rem;
        height: 1.75rem;
      }
    }
  }
}
</style>
