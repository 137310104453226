<script setup lang="ts">
import { computed, ref, watch } from 'vue';
import { onClickOutside, useWindowSize } from '@vueuse/core';
import ULogo from './ULogo.vue';
import UNavigationLink from './UNavigationLink.vue';
import UMenuButton from './UMenuButton.vue';

const props = withDefaults(
  defineProps<{
    logo?: { href: string; src: string; alt?: string; width: number; height: number; title:string };
    links?: { title: string; url: string }[];
    title?: string;
    currentUrl: URL;
  }>(),
  {
    links: [],
  }
);

const hasNavigationItems = computed(() => props.links.length > 0);
const menuOpen = ref<boolean>(false);

function toggleMenu() {
  menuOpen.value = !menuOpen.value;
}

const navigationMenu = ref();
onClickOutside(navigationMenu, (event) => {
  if (!menuOpen.value) {
    return;
  }
  event.stopPropagation();
  menuOpen.value = false;
});

const { width, height } = useWindowSize();
watch(width, () => {
  menuOpen.value = false;
});

function isActivePath(url) {
  return props.currentUrl.pathname.startsWith(url);
}
</script>

<template>
  <header class="u-page-header">
    <div class="u-page-header__inner">
      <ULogo v-bind="{ ...logo, href: '/', title: title }" />

      <template v-if="hasNavigationItems">
        <UMenuButton class="u-page-header__menu-button" :active="menuOpen" @click="toggleMenu" />

        <nav class="u-page-header__navigation">
          <UNavigationLink
            v-for="link in links"
            :key="JSON.stringify(link)"
            :label="link?.title"
            :url="link?.url"
            :active="isActivePath(link.url)"
          />
        </nav>

        <nav :class="['u-page-header__navigation-mobile', { hidden: !menuOpen }]" ref="navigationMenu">
          <UNavigationLink
            v-for="link in links"
            :key="JSON.stringify({ ...link, mobile: true })"
            :label="link?.title"
            :url="link?.url"
            :active="isActivePath(link.url)"
          />
        </nav>
      </template>
    </div>
  </header>
</template>

<style scoped lang="scss">
@layer components {
  .u-page-header {
    --header-height: 4rem;
    --header-padding: 1rem;
    --navigation-padding-inline: 3rem;
    --navigation-padding-block: 4rem;

    z-index: 1000;
    position: sticky;
    top: 0;
    background: #fff;

    &__inner {
      margin-inline: auto;
      width: min(var(--content-max-width), 100% - (var(--content-padding) * 2));
      height: var(--header-height);
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__navigation {
      display: none;
      font-size: 1.5rem;
    }

    &__navigation-mobile {
      position: absolute;
      right: 0;
      top: 100%;
      display: inline-flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 2rem;
      background: #fff;
      padding: calc(var(--navigation-padding-block) - var(--header-height)) var(--navigation-padding-inline)
        var(--navigation-padding-block);
      line-height: 1.4;
      font-size: 1.5rem;
    }

    @include mq-above($screen-small) {
      --header-height: 5rem;
      --header-padding: 2rem;
    }

    @include mq-above($screen-medium) {
      --header-height: 6.5rem;

      &__menu-button {
        display: none;
      }

      &__navigation {
        display: inline-flex;
        gap: 2rem;
      }

      &__navigation-mobile {
        display: none;
      }
    }
  }
}
</style>
